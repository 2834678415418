import { createBrowserRouter } from "react-router-dom";
import Main from "../Layouts/Main";
import Home from "../Pages/Home/Home/Home";
import ShopProduct from "../Pages/Home/HomeShop/ShopProduct";
import ProductDetails from "../Pages/Home/ProductDetails/ProductDetails";
import LoginRegistration from "../Pages/Authentication/LoginRegistration";
import Cart from "../Pages/Home/Cart/Cart";
import UserProfile from "../Pages/Home/UserProfile/UserProfile";
import CheckOut from "../Pages/Home/CheckOut/CheckOut";
import SearchData from "../Pages/Home/SearchData/SearchData";
import ChangePassword from "../Pages/Home/ChangePassword/ChangePassword";
import BecomeSeller from "../Pages/Shared/BecomeSeller/BecomeSeller";
import WishList from "../Pages/Shared/WishList/WishList";
import HomeShop from "../Pages/Home/HomeShop/HomeShop";
import AllBrand from "../Pages/AllBrand/AllBrand";
import BrandProducts from "../Pages/AllBrand/BrandProducts";
import CategoryList from "../Pages/Home/CategoryList/CategoryList";
import CategoryProducts from "../Pages/Home/CategoryList/CategoryProductList";
import ForgetPassword from "../Pages/Authentication/ForgetPassword";
import ProductSubCategory from "../Pages/Home/ProductSubCategory/ProductSubCategory";
import MatchVerifyCode from "../Pages/Authentication/MatchVerifyCode";
import ResetPassword from "../Pages/Authentication/ResetPassword";
import PrivateRoutes from "../Pages/Shared/PrivateRoutes/PrivateRoute";
import SellerType from "../Pages/Shared/BecomeSeller/SellerType";
import BecomeASeller from "../Pages/Shared/BecomeSeller/BecomeASeller";
import MyOrderList from "../Pages/Home/MyOrderList/MyOrderList";
import Message from "../Pages/Home/CheckOut/Message";
import OrderCheck from "../Pages/Home/CheckOut/OrderCheck";
import DisplayError from "../Pages/Shared/DisplayError/DisplayError";
import OrderListView from "../Pages/Home/MyOrderList/OrderListView";
import CheckoutFormBuyNow from "../Pages/Home/CheckOut/CheckOutBuyNow";
import AboutUs from "../Pages/Shared/AboutUs/page";
import VoucherProduct from "../Pages/Shared/VoucherProduct/VoucherProduct";
import Offer from "../Pages/Shared/Offer/Offer";

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Main></Main>,
        errorElement: <DisplayError></DisplayError>,
        children: [
            {
                path: '/',
                element: <Home></Home>
            },
            {
                path: '/shop/all_product/:id',
                element: <ShopProduct></ShopProduct>
            },
            {
                path: '/product/product_details/:id',
                element: <ProductDetails></ProductDetails>
            },
            {
                path: '/login',
                element: <LoginRegistration></LoginRegistration>
            },
            {
                path: '/cart',
                element: <Cart></Cart>
            },
            {
                path: '/user/profile',
                element: <UserProfile></UserProfile>
            },
            {
                path: '/order/check_out',
                element: <PrivateRoutes>
                    <CheckOut></CheckOut>
                </PrivateRoutes>
            },
            {
                path: '/search',
                element: <SearchData></SearchData>
            },
            {
                path: '/user/change_password',
                element: <ChangePassword></ChangePassword>
            },
            {
                path: '/user/forget_password',
                element: <ForgetPassword></ForgetPassword>
            },
            {
                path: '/user/forget_password/code_match',
                element: <MatchVerifyCode></MatchVerifyCode>
            },
            {
                path: '/user/reset_password',
                element: <ResetPassword></ResetPassword>
            },
            {
                path: '/seller/seller_type',
                element: <SellerType></SellerType>
            },
            {
                path: '/become_seller',
                element: <BecomeSeller></BecomeSeller>
            },
            {
                path: '/become_seller/digital_service',
                element: <BecomeASeller></BecomeASeller>
            },
            {
                path: '/my_wish_list',
                element: <WishList></WishList>
            },
            {
                path: '/my_order_list',
                element: <MyOrderList></MyOrderList>
            },
            {
                path: '/order_list_view/:id',
                element: <OrderListView></OrderListView>
            },
            {
                path: '/confirm_message',
                element: <Message></Message>
            },
            {
                path: '/confirm_order_page',
                element: <OrderCheck></OrderCheck>
            },
            {
                path: '/shop/all_shop',
                element: <HomeShop></HomeShop>
            },
            {
                path: '/brand/all_brand',
                element: <AllBrand></AllBrand>
            },
            {
                path: '/product/brand/:id',
                element: <BrandProducts></BrandProducts>
            },
            {
                path: '/product/category',
                element: <CategoryList></CategoryList>
            },
            {
                path: '/product/category/subCategory/:id',
                element: <ProductSubCategory></ProductSubCategory>
            },
            {
                path: '/product/category/product_list/:id',
                element: <CategoryProducts></CategoryProducts>
            },
            {
                path: '/about_us',
                element: <AboutUs></AboutUs>
            },
            {
                path: '/voucher_product',
                element: <VoucherProduct></VoucherProduct>
            },
            {
                path: '/offer_product',
                element: <Offer></Offer>
            },
            {
                path: '/product/order/buy_now',
                element:
                    <PrivateRoutes>

                        <CheckoutFormBuyNow></CheckoutFormBuyNow>
                    </PrivateRoutes>
            },
        ]
    },
    {
        path: '/order_list_view/:number',
        element: <OrderListView></OrderListView>,

    }
])